<template>
  <div class="top-bar pr-md-5 mobile_only no_mobile">
    <Carousel ref="carousel" class="flex items-center justify-center mobile_slider" :item-to-scroll="1"
              loop="loop" :items-to-show="1" :transition="1000" :autoplay="3000" snap-align="center" dir="rtl">
      <Slide class="top-bar__item" v-for="icon in globalOptions?.topIcons?.nodes">
        <img :src="icon?.icon?.node?.sourceUrl" :alt="icon?.icon?.node.altText" class="md-2"/>
        {{ icon.text }}
      </Slide>
    </Carousel>
  </div>
</template>
<script setup>
const {globalOptions} = useGlobalStore();
const carousel = ref(null);
console.log(globalOptions.topIcons);
// onMounted(() => {
//   carousel?.value?.updateSlideSize();
// });
// onUpdated(() => {
//   carousel?.value?.updateSlideSize();
// });
</script>
